import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { TbReport } from "react-icons/tb";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setSelectedReport } from "../../../../../app/slices/jomla/reportsSlice";

const JReportsHeader = () => {
  const dispatch = useDispatch();

  const reportTypes = useSelector((state) => state.jReports.reportTypes);

  useEffect(() => {
    if (reportTypes[0]?.isSelected) {
      setReportType("cash");
    } else if (reportTypes[1]?.isSelected) {
      setReportType("rep");
    } else if (reportTypes[2]?.isSelected) {
      setReportType("rep_statistics");
    } else if (reportTypes[3]?.isSelected) {
      setReportType("added_j_item");
    } else if (reportTypes[4]?.isSelected) {
      setReportType("sold_j_item");
    } else {
      setReportType(null);
    }
  }, [reportTypes]);

  const [reportType, setReportType] = useState(null);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            التقارير
          </Typography>
        }
        avatar={<TbReport size={20} />}
        action={
          <Stack direction="row" spacing={2}>
            <Autocomplete
              sx={{ width: "30vh" }}
              options={reportTypes}
              getOptionLabel={(option) => option.type}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField
                  fullWidth
                  required
                  {...params}
                  label="اختر نوع التقرير"
                />
              )}
              onChange={(event, value) => {
                setReportType(value ? value.name : null);
              }}
              value={
                reportTypes.filter((x) => x.name === reportType)[0]
                  ? reportTypes.filter((x) => x.name === reportType)[0]
                  : null
              }
            />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<BsSearch />}
              onClick={() => dispatch(setSelectedReport(reportType))}
            >
              بحث
            </Button>
          </Stack>
        }
      />
    </Card>
  );
};

export default JReportsHeader;
