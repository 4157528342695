import { Grid } from "@mui/material";
import React from "react";
import ReportsHeader from "./home/header";
import { useSelector } from "react-redux";
import JCashTable from "./home/table/payments";
import JRepTable from "./home/table/rep";
import JRepStatisticsTable from "./home/table/rep_statistics";
import AddedJItems from "./home/table/addedItems";
import SoldJItems from "./home/table/soldItems";

const JReport = () => {
  const _ReportType = useSelector((state) => state.jReports.reportTypes);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReportsHeader />
      </Grid>
      {_ReportType[0]?.isSelected && (
        <Grid item xs={12}>
          <JCashTable />
        </Grid>
      )}
      {_ReportType[1]?.isSelected && (
        <Grid item xs={12}>
          <JRepTable />
        </Grid>
      )}
      {_ReportType[2]?.isSelected && (
        <Grid item xs={12}>
          <JRepStatisticsTable />
        </Grid>
      )}
      {_ReportType[3]?.isSelected && (
        <Grid item xs={12}>
          <AddedJItems />
        </Grid>
      )}
      {_ReportType[4]?.isSelected && (
        <Grid item xs={12}>
          <SoldJItems />
        </Grid>
      )}
    </Grid>
  );
};

export default JReport;
