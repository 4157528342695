import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jCash(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-reports/cash?${params}`);
  },
  jRep() {
    return api.get(`/api/v1/j-reports/rep`);
  },
  jRepStatistics(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-reports/rep-statistics?${params}`);
  },
  jItemAddedSummaryReport(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-reports/j-item-summary?${params}`);
  },
  jItemSoldSummeryReport(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-reports/j-invoice-item-summary?${params}`);
  },
};
